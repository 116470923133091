import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/css/Navbar.css";
import { FaCrown } from "react-icons/fa";
import MainLogo from "../Assets/img/mainlogo.png";
import MenuLogo from "../Assets/img/menulogo.png";

const BottomBar = () => {
    const [sidenavs, changeSidenav] = useState(false);
    useEffect(() => {
        if (sidenavs) {
            document.getElementById("menu-sidebar").style.width = "75%";
            document.querySelector(".menu-sidebar").style.display = "block";
            document.querySelector(".menu-sidebar-overlay").style.display =
                "block";
            document.querySelector(".menu-sidebar-overlay").style.opacity = "1";
        } else {
            document.getElementById("menu-sidebar").style.width = "0";
            document.querySelector(".menu-sidebar").style.display = "none";
            document.querySelector(".menu-sidebar-overlay").style.display =
                "none";
            document.querySelector(".menu-sidebar-overlay").style.opacity = "0";
        }
    }, [sidenavs]);

    return (
        <div>
            <div className="mobile-bottomnavbar">
                <Link to="/casino" className="mobile-bottomnavbarbutton">
                    <img src={MenuLogo} alt="menu" />
                    <h3>CASİNO</h3>
                </Link>
                <span className="mobile-bottomnavbarbutton">
                    <img src={MenuLogo} alt="menu" />
                    <h3>CANLI DESTEK</h3>
                </span>
                <span
                    className="mobile-bottomnavbarbutton"
                    onClick={() => {
                        changeSidenav(!sidenavs);
                    }}
                >
                    <img src={MenuLogo} alt="menu" />
                    <h3>MENÜ</h3>
                </span>
            </div>
            <div id="menu-sidebar" className="menu-sidebar">
                <div className="menu-sidebar-header">
                    <div className="menu-sidebar-header-main">
                        <div className="menu-sidebar-header-main-main">
                            <div className="menu-sidebar-header-title">
                                <h3>MENÜ</h3>
                            </div>
                            <div
                                className="menu-sidebar-header-close"
                                onClick={() => {
                                    changeSidenav(!sidenavs);
                                }}
                            >
                                <div className="close-line">
                                    <span className="straightline1"></span>
                                    <span className="straightline2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="menu-sidebar-header-main-after"></div>
                    </div>
                </div>
                <div className="menu-sidebar-menu">
                    <Link
                        to="/vip"
                        className="menu-sidebar-menu-item"
                        onClick={() => {
                            changeSidenav(!sidenavs);
                        }}
                    >
                        <FaCrown fill="#ffd91d" class="crown-logo" enableBackground="#ffd91d"/>
                        <span>VIP PROGRAMI</span>
                    </Link>
                    <Link
                        to="/bonuses"
                        className="menu-sidebar-menu-item"
                        onClick={() => {
                            changeSidenav(!sidenavs);
                        }}
                    >
                        <img class="casino-logo" src="https://s3.trbdata.com/hub/b4/pages/RsrX3gAFcXjd0em6ZUki9TkbEMbYDMMJdjNVbM1U.svg" />
                        BONUSLAR
                    </Link>
                    <Link
                        to="/casino"
                        className="menu-sidebar-menu-item"
                        onClick={() => {
                            changeSidenav(!sidenavs);
                        }}
                    >
                        <img class="casino-logo" src="https://s3.trbdata.com/hub/b4/pages/WJSgkcVgDlYISpM1wCS99MqfNqtDeFPdWOzleXLv.svg" />
                        CASİNO
                    </Link>
                </div>
            </div>
            <div
                className="menu-sidebar-overlay"
                onClick={() => {
                    changeSidenav(!sidenavs);
                }}
            ></div>
        </div>
    );
};

const UnloggedNavbar = () => {
    return (
        <div className="mainnav">
            <BottomBar />
            <nav className="header-flc">
                <div className="header-main">
                    <div className="header-left">
                        <Link to="/" className="header-logo">
                            <img src={MainLogo} alt="logo" />
                        </Link>
                    </div>
                    <div className="header-middleleftlogged">
                        <div className="pcnavbar">
                            <Link
                                to="/casino"
                                className="header-btn sports-btn"
                            >
                                <img class="casino-logo" src="https://s3.trbdata.com/hub/b4/pages/WJSgkcVgDlYISpM1wCS99MqfNqtDeFPdWOzleXLv.svg" />
                                CASİNO
                            </Link>
                            <Link
                                to="/bonuses"
                                className="header-btn casino-btn"
                            >
                                <img class="casino-logo" src="https://s3.trbdata.com/hub/b4/pages/RsrX3gAFcXjd0em6ZUki9TkbEMbYDMMJdjNVbM1U.svg" />
                                BONUSLAR
                            </Link>
                            <Link to="/vip" className="header-btn sports-btn">
                                <FaCrown fill="#ffd91d" class="casino-logo crown-logo" enableBackground="#ffd91d"/>
                                VIP PROGRAMI
                            </Link>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="unlogged-buttons">
                            <Link to="/login" className="header-btn login-btn">
                                GİRİŞ
                            </Link>
                            <Link
                                to="/register"
                                className="header-btn register-btn"
                            >
                                Üye Ol
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

const LoggedNavbar = (detailsUsers) => {
    detailsUsers = detailsUsers.detailsUser;
    //foreach element in detailsUsers if it is int or float, convert it to string
    for (var key in detailsUsers) {
        if (typeof detailsUsers[key] === "number") {
            detailsUsers[key] = detailsUsers[key].toString();
        }
    }

    const [sidebar, setSidebar] = useState(false);
    const [messages, setMessages] = useState(0);
    const [balance, setBalance] = useState(0);
    const [VipStatus, setVipStatus] = useState("Bronze");
    const [freespinAmount, setFreespinAmount] = useState(0);

    //if sidenavs is already opened changeSidenav but sidenavs false, changeSidenav to true
    useEffect(() => {
        setMessages(detailsUsers.messages);
        setBalance(detailsUsers.balance);
        setVipStatus(detailsUsers.vipStatus);
        setFreespinAmount(detailsUsers.freespinAmount);
    }, []);

    useEffect(() => {
        if (sidebar) {
            document.getElementById("profile-sidebar").style.width = "75%";
            document.getElementById("profile-sidebar").style.opacity = "1";
            document.getElementById("profile-sidebar").style.visibility =
                "visible";
            document.getElementById("profile-sidebar").style.display = "block";
            document.querySelector(".profile-sidebar-overlay").style.display =
                "block";
            document.querySelector(".profile-sidebar-overlay").style.opacity =
                "1";
        } else {
            document.getElementById("profile-sidebar").style.width = "0";
            document.getElementById("profile-sidebar").style.opacity = "0";
            document.getElementById("profile-sidebar").style.visibility =
                "hidden";
            document.getElementById("profile-sidebar").style.display = "none";
            document.querySelector(".profile-sidebar-overlay").style.display =
                "none";
            document.querySelector(".profile-sidebar-overlay").style.opacity =
                "0";
        }
    }, [sidebar]);

    useEffect(() => {
        if (messages > 0) {
            document.querySelector(
                ".profile-sidebar-messages-main-messagetext"
            ).innerText = messages + " YENİ MESAJINIZ VAR";
            document.querySelector(
                ".profile-sidebar-messages-main-messagetext"
            ).style.color = "#0078FF";
        }
    }, [messages]);

    useEffect(() => {
        document.querySelector("#balance").innerText = "₺" + balance;
    }, [balance]);

    const openMenuButton = (id) => {
        return () => {
            //add activated class to clicked button
            document
                .getElementById(id + "button")
                .classList.toggle("activated");
            if (id === 1) {
                document
                    .getElementById(2 + "button")
                    .classList.remove("activated");
                document.getElementById(2 + "menu").style.height = "0";
                document.getElementById(2 + "menu").style.display = "none";
                document.getElementById(2 + "menubas").style.height = "0";
            } else {
                document
                    .getElementById(1 + "button")
                    .classList.remove("activated");
                document.getElementById(1 + "menu").style.height = "0";
                document.getElementById(1 + "menu").style.display = "none";
                document.getElementById(1 + "menubas").style.height = "0";
            }

            //if activated class is added, open the menu
            if (
                document
                    .getElementById(id + "button")
                    .classList.contains("activated")
            ) {
                //count the number of items in the menu and set the height of the menu
                document.getElementById(id + "menu").style.display = "flex";
                document.getElementById(id + "menu").style.height = "100%";
                document.getElementById(id + "menubas").style.height = "50%";
            } else {
                document.getElementById(id + "menu").style.display = "none";
                document.getElementById(id + "menubas").style.height = "0";
            }
        };
    };

    return (
        <div className="mainnav">
            <div
                className="profile-sidebar-overlay"
                onClick={() => {
                    setSidebar(!sidebar);
                }}
            ></div>
            <div id="profile-sidebar" className="profile-sidebar">
                <div className="profile-sidebar-header">
                    <div className="profile-sidebar-header-main">
                        <div className="profile-sidebar-header-main-main">
                            <div className="profile-sidebar-header-title">
                                <h3>Profil</h3>
                            </div>
                            <div
                                className="profile-sidebar-header-close"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <div className="close-line">
                                    <span className="straightline1"></span>
                                    <span className="straightline2"></span>
                                </div>
                            </div>
                        </div>
                        <div className="profile-sidebar-header-main-after"></div>
                    </div>
                </div>
                <div className="profile-sidebar-messages">
                    <div className="profile-sidebar-messages-main">
                        <div className="profile-sidebar-messages-main-title">
                            <h3>MESAJLAR</h3>
                        </div>
                        <div className="profile-sidebar-messages-main-messagebar">
                            <div className="profile-sidebar-messages-main-messagetext">
                                HİÇ MESAJINIZ YOK
                            </div>
                        </div>
                    </div>
                    <div className="profile-sidebar-messages-after"></div>
                </div>
                <div className="profile-sidebar-userinfo">
                    <div className="profile-sidebar-userinfo-main">
                        <div className="profile-sidebar-userinfo-balance">
                            <h3>BAKİYE&nbsp;</h3>
                            <h3 className="balance-color">₺ {balance}</h3>
                        </div>
                        <div className="profile-sidebar-userinfo-status">
                            <div className="profile-sidebar-userinfo-status-vip">
                                <h3>VIP SEVİYENİZ</h3>
                                <h3>{VipStatus}</h3>
                            </div>
                            <div className="profile-sidebar-userinfo-status-freespin">
                                <h3>FREESPINS</h3>
                                <h3>{freespinAmount}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-sidebar-menu">
                    <div
                        id="1button"
                        className="profile-sidebar-menu-button"
                        onClick={openMenuButton(1)}
                    >
                        <div className="profile-sidebar-menu-button-main">
                            <h3>HESABIM</h3>
                            <span className="profile-sidebar-menu-button-icon">
                                >
                            </span>
                        </div>
                    </div>
                    <div
                        id="1menubas"
                        className="profile-sidebar-menu-list twofive"
                    >
                        <div
                            id="1menu"
                            className="profile-sidebar-menu-list-main"
                        >
                            <Link
                                to="/profile"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>HESABIM</span>
                            </Link>
                            <Link
                                to="/gamblings"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>BAHİS GEÇMİŞİ</span>
                            </Link>
                            <Link
                                to="/verification"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>HESAP DOĞRULAMA</span>
                            </Link>
                            <Link
                                to="/messages"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>MESAJLAR</span>
                            </Link>
                        </div>
                    </div>
                    <div
                        id="2button"
                        className="profile-sidebar-menu-button"
                        onClick={openMenuButton(2)}
                    >
                        <div className="profile-sidebar-menu-button-main">
                            <h3>FİNANS</h3>
                            <span className="profile-sidebar-menu-button-icon">
                                >
                            </span>
                        </div>
                    </div>
                    <div id="2menubas" className="profile-sidebar-menu-list">
                        <div
                            id="2menu"
                            className="profile-sidebar-menu-list-main"
                        >
                            <Link
                                to="/deposit"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>PARA YATIR</span>
                            </Link>
                            <Link
                                to="/withdraw"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>PARA ÇEKME</span>
                            </Link>
                            <Link
                                to="/transactions"
                                className="profile-sidebar-menu-list-item"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span>FİNANS GEÇMİŞİ</span>
                            </Link>
                        </div>
                    </div>
                    <Link
                        to="/logout"
                        className="profile-sidebar-menu-button"
                        onClick={() => {
                            setSidebar(!sidebar);
                        }}
                    >
                        <div className="profile-sidebar-menu-button-main">
                            <h3>ÇIKIŞ</h3>
                        </div>
                    </Link>
                </div>
            </div>
            <div
                className="profile-sidebar-overlay"
                onClick={() => {
                    setSidebar(!sidebar);
                }}
            ></div>
            <BottomBar />
            <nav className="header-flc">
                <div className="header-main">
                    <div className="header-left">
                        <Link to="/" className="header-logo">
                            <img src={MainLogo} alt="logo" />
                        </Link>
                    </div>
                    <div className="header-middleleftlogged">
                        <div className="pcnavbar">
                            <Link
                                to="/casino"
                                className="header-btn sports-btn"
                            >
                                <img class="casino-logo" src="https://s3.trbdata.com/hub/b4/pages/WJSgkcVgDlYISpM1wCS99MqfNqtDeFPdWOzleXLv.svg" />
                                CASİNO
                            </Link>
                            <Link
                                to="/bonuses"
                                className="header-btn casino-btn"
                            >
                                <img class="casino-logo" src="https://s3.trbdata.com/hub/b4/pages/RsrX3gAFcXjd0em6ZUki9TkbEMbYDMMJdjNVbM1U.svg" />
                                BONUSLAR
                            </Link>
                            <Link to="/vip" className="header-btn sports-btn">
                                <FaCrown fill="#ffd91d" class="casino-logo crown-logo" enableBackground="#ffd91d"/>
                                VIP PROGRAMI
                            </Link>
                        </div>
                    </div>
                    <div className="header-middlelogged">
                        <div className="logged-buttons">
                            <Link
                                to="/history"
                                id="balance"
                                className="header-btn balance-btn"
                            >
                                ₺ {balance}
                            </Link>
                            <Link
                                to="/deposit"
                                className="header-btn deposit-btn"
                            >
                                Para Yatır
                            </Link>
                        </div>
                    </div>
                    <div className="header-rightlogged">
                        <div className="logged-menubtn">
                            <div
                                className="header-btn profile-btn"
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                            >
                                <span className="straightline"></span>
                                <span className="straightline"></span>
                                <span className="straightline"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};
const Navbar = (detaylar) => {
    var data = detaylar.detaylar;
    var logged = detaylar.detaylar.logged;
    if (logged) {
        var returnData = <LoggedNavbar detailsUser={data} />;
    } else {
        var returnData = <UnloggedNavbar />;
    }
    return <BottomBar /> && returnData;
};

export default Navbar;
