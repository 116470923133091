import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../Assets/css/PopularGames.css";
import axios from "axios";
import { Link } from "react-router-dom";

//send request to asbet001.com/populargames
//get the response and set the state
//map the state and show the games
//return the games

const PopularGames = (veri) => {
  
  var navigate = useNavigate();

  var isMobile = window.innerWidth < 768;
  var [games, setGames] = useState([]);
  useEffect(() => {
    axios
      .get("https://asbet001.com/v1/popularGames?mobile=" + isMobile)
      .then((response) => {
        var detail = response.data;
        var detail = detail.games;
        setGames(detail);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getGameUrl = (gameid) => {
    navigate("/playGame/" + gameid);
    
  };

  const playGame = (gameid) => {
    return () => {
        getGameUrl(gameid);
    };
  };

  veri = veri.veri;
  return (
    <div className="PopularMain">
      <h3 className="PopularTitle">POPÜLER</h3>
      <div className="GamesGrid">
        <div className="PopularGames">
          {games.map((game) => (
            <div
              className="PopularGame"
              key={game.gameid}
              onClick={playGame(game.gameid)}
            >
              <img src={game.iconurl2} alt={game.name} />
              <div className="hiddenGameInfo">
                <p>{game.name}</p>
                <div className="playBTN">OYNA</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Link to="/casino" className="PopularLink">
        DAHA FAZLA
      </Link>
    </div>
  );
};

export default PopularGames;
