import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "../Assets/img/settingsIcon.png";
import SearchIcon from "../Assets/img/searchIcon.webp";
import "../Assets/css/CasinoGames.css";
import axios from "axios";
const CasinoGames = () => {
    var navigate = useNavigate();

    
    var [searchInput, setSearchInput] = React.useState("");
    
    var [newGameList, setNewGameList] = React.useState("");
    
    var [showMoreBTN, setShowMoreBTN] = React.useState(true);
    
    var [allGames, setAllGames] = React.useState([]);

    var [gameList, setGameList] = React.useState([]);
    useEffect(() => {
        document.getElementById("preloader").style.display = "flex";
        //send request to API to get all games
        axios
            .get("https://asbet001.com/v1/getGameList")
            .then((response) => {
                setAllGames(response.data);
                setGameList(response.data.slice(0, 24));
                document.getElementById("preloader").style.display = "none";
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        //if searchInput is empty, set gameList to first 24 games from allGames
        if (newGameList === "") {
            getGames(0, 24);
            setShowMoreBTN(true);
        } else {
            //if searchInput is not empty, filter allGames and set gameList to filtered games
            setGameList(
                allGames.filter((game) => {
                    return game.name
                        .toLowerCase()
                        .includes(newGameList.toLowerCase());
                })
            );
            setShowMoreBTN(false);
        }
    }, [newGameList]);
    var getGames = (from, to) => {
        if (from === "as" && to === "as") {
            from = 0;
            to = gameList.length + 24;
            //set gameList to first 24 games from allGames
            setGameList(allGames.slice(from, to));
        } else {
            setGameList(allGames.slice(from, to));
        }
    };

    const searchGame = (e) => {
        e.preventDefault();
        //check if pressed backspace and remove last character from searchInput
        //if its delete all
        if (e.nativeEvent.inputType === "deleteContent") {
            getGames(0, 24);
            setShowMoreBTN(true);
        }
        if (e.nativeEvent.inputType === "deleteContentBackward") {
            if (searchInput.length === 0 || searchInput.length === 1 || searchInput === "") {
                getGames(0, 24);
                setShowMoreBTN(true);
            } else {
                setNewGameList(searchInput.slice(0, -1));
            }
            setSearchInput(searchInput.slice(0, -1));
        } else {
            setShowMoreBTN(false);
            setSearchInput(searchInput + e.nativeEvent.data);
            setNewGameList(searchInput + e.nativeEvent.data);
        }
        
    };

    var PlayGame = (gameid) => {
        navigate("/playGame/" + gameid);
    };
    useEffect(() => {
        //get games from API
        getGames(0, 24);
    }, []);

    var Providers = [
        "Novomatic",
        "NetEnt",
        "Playtech",
        "Play'n GO",
        "Pragmatic Play",
        "Thunderkick",
        "Red Tiger Gaming",
        "Betsoft Gaming",
        "Playson",
    ];

    var [ActiveProvider, setActiveProvider] = React.useState(0);

    const selectedDefaultProvider = () => {
        setActiveProvider(0);
    };

    useEffect(() => {
        //show More button onchange
        if (showMoreBTN === true) {
            document.getElementById("LoadMoreBTN").style.display = "block";
        } else {
            document.getElementById("LoadMoreBTN").style.display = "none";
        }
    }, [showMoreBTN]);

    //onload, give ProviderActive class to first child of ProviderUl,
    useEffect(() => {
        document
            .querySelectorAll(".ProviderOption")[0]
            .classList.add("ProviderActive");
    }, []);

    //onclick ProviderOption, remove ProviderActive class from all ProviderOptions and add ProviderActive class to clicked ProviderOption
    useEffect(() => {
        document
            .querySelectorAll(".ProviderOption")
            .forEach((element, index) => {
                element.addEventListener("click", function () {
                    document
                        .querySelectorAll(".ProviderOption")
                        .forEach((element) => {
                            element.classList.remove("ProviderActive");
                        });
                    element.classList.add("ProviderActive");
                    OpenProviderList();
                });
            });
    }, []);

    const OpenProviderList = () => {
        if (document.querySelector(".ProviderUl") !== null) {
            document.querySelector(".ProviderUl").classList.toggle("openedUl");
        }
    };

    return (
        <div className="CasinoMain">
            <div className="CasinoHeader">
                <div className="ProviderMain">
                    <div className="ProviderList">
                        <img
                            src={SettingsIcon}
                            alt="Settings"
                            className="ProviderIcon"
                            onClick={() => {
                                OpenProviderList();
                            }}
                        />
                        <span
                            className="ProviderTitle"
                            onClick={() => {
                                OpenProviderList();
                            }}
                        >
                            Sağlayıcılar
                        </span>
                        <ul className="ProviderUl">
                            <div className="ProviderLister">
                                <li
                                    className="ProviderOption"
                                    onClick={() => {
                                        selectedDefaultProvider();
                                    }}
                                >
                                    Hepsi
                                </li>
                                {Providers.map((provider, index) => {
                                    return (
                                        <li
                                            className="ProviderOption"
                                            key={index}
                                            onClick={() => {
                                                setActiveProvider(index);
                                            }}
                                        >
                                            {provider}
                                        </li>
                                    );
                                })}
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="CasinoSearch">
                    <span>
                        <img src={SearchIcon} alt="Search" />
                    </span>
                    <input
                        type="text"
                        placeholder="Oyun Ara"
                        className="CasinoSearchInput"
                        onChange={searchGame}
                        value={searchInput}
                    />
                </div>
            </div>
            <div className="CasinoContent">
                <div className="CasinoGames">
                    {gameList.map((game, index) => {
                        return (
                            <div className="CasinoGame" key={index}>
                                <img
                                    src={game.iconurl2}
                                    alt={game.name}
                                    className="CasinoGameImage"
                                    onClick={() => {
                                        PlayGame(game.gameid);
                                    }}
                                />
                                <div className="CasinoGameTitle">
                                    {game.gameName}
                                </div>
                                <div
                                    className="hiddenGameInfos"
                                    onClick={() => {
                                        PlayGame(game.gameid);
                                    }}
                                >
                                    <p>{game.name}</p>
                                    <div className="playBTN">OYNA</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="CasinoLoadMore">
                    <div
                        id="LoadMoreBTN"
                        className="LoadMoreBTN"
                        onClick={() => {
                            getGames("as", "as");
                        }}
                    >
                        Daha Fazla
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CasinoGames;
