import React, { useEffect } from "react";
import "../Assets/css/Register.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Home from "./Home";
import RegisterStep1 from "../Components/RegisterStep1";
import RegisterStep2 from "../Components/RegisterStep2";

const Register = (getdata) => {
    getdata = getdata.getdata;

    const navigate = useNavigate();
    //check if user is logged in
    if (getdata.logged === true) {
        //router push to home
        navigate("/");
    }

    //change title
    useEffect(() => {
        document.title = "Kayıt Ol | AsBet";
        document.getElementById("RegisterStep1").style.display = "block";
        document.getElementById("RegisterStep2").style.display = "none";
        document.body.id = "RegisterPage1";
    }, []);


    return (
        <div className="RegisterPage">
            <Home />
            <Link to="/" className="LoginBackground"></Link>
            <RegisterStep1 />
            <RegisterStep2 />
        </div>
    );
};

export default Register;
