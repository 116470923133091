import React from "react";
import "../Assets/css/CasinoSlider.css";
import Slider1 from "../Assets/img/Sliders/Casino/Big__Bass__Hold___Spinner_MegawaysTM_mobile.jpg";
import Slider2 from "../Assets/img/Sliders/Casino/pragmatic_drops_wins_2m_mobile_v4.jpg";
import Slider3 from "../Assets/img/Sliders/Casino/wazdan-20k-tournament-feb-mobile-v2.jpg";
const colors = [
  "/static/media/Big__Bass__Hold___Spinner_MegawaysTM_mobile.031d3267c94ef1fa7930.jpg",
  "/static/media/pragmatic_drops_wins_2m_mobile_v4.fb03e81b1f025678fa84.jpg",
  "/static/media/wazdan-20k-tournament-feb-mobile-v2.b4b21d19a23129389d52.jpg",
];
const delay = 2500;

const CasinoSlider = () => {
  var isMobile = window.innerWidth < 768;
  if (isMobile) {
    colors[0] = Slider1;
    colors[1] = Slider2;
    colors[2] = Slider3;
  } else {
    colors[0] = Slider1;
    colors[1] = Slider2;
    colors[2] = Slider3;
  }

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshows">
      <div
        className="slideshowSliders"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((imgurl, index) => (
          <div className="slider" key={imgurl}>
            <div className="sliderButtonDiv">
              <button className="sliderButton">OYNA</button>
            </div>
            <img className="slides" key={index} src={imgurl}></img>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CasinoSlider;
