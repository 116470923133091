import React, { useEffect } from "react";
import "../Assets/css/Login.css";
import Logo from "../Assets/img/mainlogo.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Home from "./Home";
import axios from "axios";

const ForgotPassword = (getdata) => {
  getdata = getdata.getdata;
  const navigate = useNavigate();
  //check if user is logged in
  if (getdata.logged === true) {
    //router push to home
    navigate("/");
  }

  //login request and managment
  var [response, setResponse] = useState("asdasd");
  var [responseShow, setResponseShow] = useState("none");

  //change title
  useEffect(() => {
    document.title = "Şifremi Unuttum | AsBet";
  }, []);

  useEffect(() => {
    //response show effect
    if (responseShow === "flex") {
      setTimeout(() => {
        setResponseShow("none");
      }, 3000);
    }
  }, [responseShow]);

  var forgotRequest = () => {
    //check if email is empty
    if (
      document.querySelector("#email").value === ""
    ) {
      setResponse("Email Boş Olamaz.");
      setResponseShow("flex");
      return;
    } else {
      //login request with no cors
      axios
        .post(
          "https://asbet001.com/v1/ForgotPassword",
          {
            email: document.querySelector("#email").value
          },
          {
            headers: {
              "Cache-Control": "no-cache",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          var response = res.data;
          console.log('login response ', response);
          if (response.status === true) {
            setResponse(response.message);
            setResponseShow("flex");
          } else {
            setResponse(response.message);
            setResponseShow("flex");
          }
        })
        .catch((err) => {
          setResponse(
            "Şifre Sıfırlama Esnasında Bir Hata Oluştu. Lütfen Canlı Desteğe Bağlanın."
          );
          setResponseShow("flex");
        });
    }
  };
  useEffect(() => {
    //registerform on keydown
    document.getElementById("LoginContent").onkeydown = function (e) {
        if (e.key === "Enter") {
          forgotRequest();
        }
    };
}, []);


  return (
    <div className="LoginPage">
      <Home lastShowStatus={true} />
      <Link to="/" className="LoginBackground"></Link>
      <div className="LoginMain" id="LoginContent">
        <div className="LoginHeader">
          <img src={Logo} alt="Logo" className="LoginHeaderLogo" />
          <div className="LoginHeaderRight">
            <Link to="/login" className="LoginHeaderRegister">
              Giriş Yap
            </Link>
            <Link to="/" className="LoginHeaderClose">
              <span className="LoginHeaderLine line1"></span>
              <span className="LoginHeaderLine line2"></span>
            </Link>
          </div>
        </div>
        <div className="LoginContent">
          <h1 className="LoginHeaderTitle">ŞİFRE SIFIRLA</h1>
          <p className="LoginHeaderResponse" style={{ display: responseShow }}>
            {response}
          </p>
          <div className="LoginForm">
            <input
              type="text"
              placeholder="Email"
              id="email"
              className="LoginFormInput"
            />
            <button className="LoginFormButton" onClick={forgotRequest}>
              ŞİFRE SIFIRLA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
