import React, { useEffect } from "react";
import CasinoSlider from "../Components/CasinoSlider";
import CasinoGames from "../Components/CasinoGames";
import "../Assets/css/Home.css";
const Casino = () => {
  //change title
  useEffect(() => {
    document.title = "Casino | AsBet";
  }, []);
  return (
    <div className="mainhome">
      <div className="maincontainer">
        <CasinoSlider />
        <CasinoGames />
      </div>
    </div>
  );
};

export default Casino;
