import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "../Assets/css/LastWinners.css";
import Loading from "../Assets/img/loading.gif";
import axios from "axios";

const delay = 2500;

const LastWinnersData = [];

const LastWinners = (detaylar) => {
    //if its mobile, slide 3 item at once
    var changeData = 100;
    if (window.innerWidth > 768) {
        changeData = 30;
    }
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);
    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    //if there is detaylar.logged then show last winners

    var [LastWinnerData, setLastWinnerData] = React.useState([]);

    React.useEffect(() => {
            //send request to get last winners
            axios
                .get("https://asbet001.com/v1/getLastWinners")
                .then((response) => {
                    console.log(response)
                    var detail = response.data;
                    for (var i = 0; i < detail.length; i++) {
                        var winnerName = detail[i].name;
                        console.log(detail)
                        winnerName = winnerName.toUpperCase();
                        var winAmount = detail[i].amount;
                        LastWinnersData.push({
                            winnerName: winnerName,
                            winnerPrize: winAmount,
                            gameid: detail[i].game,
                            gameImg: detail[i].gameimg,
                            winnerGame: detail[i].gamename,
                        });
                        setLastWinnerData(LastWinnersData);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
    }, []);

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === LastWinnersData.length - 3 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);
    return (
        <div className="LastWinnersMain">
            <h3 className="LastWinnersTitle">SON KAZANANLAR</h3>
            {LastWinnerData.length > 0 ? (
            <div
                className="LastWinnersSlider"
                style={{
                    transform: `translate3d(${-index * changeData}%, 0, 0)`,
                }}
            >
                {LastWinnersData.map((winner, index) => {
                    return (
                        <Link to={`/playGame/${winner.gameid}`} key={index} className="LastWinnersCard">
                            <div className="bigslider">
                                <div key={index} className="LastWinnersCard">
                                    <div className="LastWinnersCardImg">
                                        <img
                                            src={winner.gameImg}
                                            alt={winner.winnerGame}
                                        />
                                    </div>
                                    <div className="LastWinnersCardInfo">
                                        <h4>{winner.winnerName}</h4>
                                        <p>{winner.winnerGame}</p>
                                    </div>
                                    <div className="LastWinnersCardPrize">
                                        <p>₺ {winner.winnerPrize}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>

            ) : (
                <div className="LastWinnersLoading">
                    <img src={Loading} alt="loading" />
                </div>
            )}
        </div>
    );
};

export default LastWinners;
