import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../Assets/css/Bonuses.css";
import axios from "axios";

const bonuses = [];

const Bonuses = (detail) => {
    var navigate = useNavigate();
    var [openBonusPop, setOpenBonusPop] = useState(false);
    var [bonusPop, setBonusPop] = useState(0);
    var [popupTitle, setPopupTitle] = useState("Başlık");
    var [popupContent, setPopupContent] = useState("Paragraf");

    useEffect(() => {
        if (bonusPop === 0) return;
        var bonusDetails = bonuses.filter(
            (bonus) => bonus.id === bonusPop
        );
        bonusDetails = bonusDetails[0];
        setPopupTitle(bonusDetails.title);
        setPopupContent(bonusDetails.description);
    }, [bonusPop]);

    useEffect(() => {
        if (openBonusPop) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [openBonusPop]);

    function goToRegister() {
        navigate("/register");
    }

    var logged = detail.detaylar.logged;
    

    React.useEffect(() => {
        //send request to /api/pages/home/bonuses.php
        axios.get("https://asbet001.com/v1/bonuses.php").then((response) => {
            console.log("bonuses api ",response.data);
            var data = response.data;
            data && data.forEach((element) => {
            //push index and imgurl
                bonuses.push(element);
            });
        });
        }
    , []);
    
    function openBonusPoper(id) {
        setOpenBonusPop(true);
        setBonusPop(id);
    }
    useEffect(() => {
        document.title = "Bonuslar | AsBet";
        //on computer, if its click to bonuses-popup, close the popupq
        document
            .querySelector(".bonuses-popup")
            .addEventListener("click", function (e) {
                if (e.target === document.querySelector(".bonuses-popup")) {
                    setOpenBonusPop(false);
                }
            });
    }, []);
    return (
        <div className="bonuses-main">
            <div className="bonuses-header">
                <h1 className="bonuses-header-title">BONUSLAR</h1>
            </div>
            <div className="bonuses-content">
                {bonuses.map((bonus, index) => {
                    console.log(index);
                    return (
                        <div key={bonus.id} className="bonuses-content-box">
                            <div className="bonuses-content-img">
                                <img
                                    src={bonus.imgurl}
                                    alt={bonus.title}
                                />
                            </div>
                            <div className="bonuses-content-desc">
                                <h2 className="bonus-name">
                                    {bonus.title}
                                </h2>
                                <div className="bonus-content-desc-buttons">
                                    {logged ? null : (
                                        <button
                                            className="bonus-content-register-button"
                                            onClick={goToRegister}
                                        >
                                            KAYIT OL
                                        </button>
                                    )}
                                    <button
                                        className="bonus-content-desc-button"
                                        onClick={() =>
                                            openBonusPoper(bonus.id)
                                        }
                                    >
                                        ŞARTLARI OKU
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div
                className="bonuses-popup"
                style={{ display: openBonusPop ? "flex" : "none" }}
            >
                <div className="bonuses-popupcan">
                    <div className="bonuses-popup-header">
                        <h1 className="bonuses-popup-header-title">
                            {popupTitle}
                        </h1>
                        <button
                            className="bonuses-popup-header-close"
                            onClick={() => setOpenBonusPop(false)}
                        >
                            <span className="bonuses-popup-header-close-line"></span>
                            <span className="bonuses-popup-header-close-line"></span>
                        </button>
                    </div>
                    <div className="bonuses-popup-content">
                        <h1>Bonus Kuralları</h1>
                        <p>{popupContent}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bonuses;
