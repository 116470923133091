import React from 'react'
import '../Assets/css/PreLoader.css'
import Logo from '../Assets/img/mainlogo.png'
const PreLoader = () => {
  return (
    <div id="preloader">
        <div id="status">
            <img src={Logo} alt="logo" />
            <h3>YÜKLENİYOR...</h3>
        </div>
    </div>
  )
}

export default PreLoader