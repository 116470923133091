import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Assets/css/Profile.css";
import axios from "axios";

const Profile = (detaylar) => {
    var navigator = useNavigate();
    var logged = detaylar.getdata.logged;
    var [response, setResponse] = useState(["asd"]);
    var [showResponse, setShowResponse] = useState("none");
    var [colorResponse, setColorResponse] = useState("red");
    var [userDetails, setUserDetails] = useState([]);
    if (logged === false) {
        navigator("/");
    }

    useEffect(() => {
        axios
            .get("https://asbet001.com/v1/getUserDetails")
            .then((response) => {
                var detail = response.data;
                detail.firstname =
                    detail.firstname.charAt(0).toUpperCase() +
                    detail.firstname.slice(1).toLowerCase();
                detail.middlename =
                    detail.middlename.charAt(0).toUpperCase() +
                    detail.middlename.slice(1).toLowerCase();
                detail.lastname =
                    detail.lastname.charAt(0).toUpperCase() +
                    detail.lastname.slice(1).toLowerCase();
                //remove spaces from phone number
                detail.phone = detail.phone.replace(/\s/g, "");
                detail.phone = detail.phone.slice(3, 13);
                if (detail.address !== "") {
                    document.getElementById("address").value = detail.address;
                }
                var genders = ["Erkek", "Kadın"];
                var gendercan = detail.gender;
                var days = [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                ];
                var months = [
                    "Ocak",
                    "Şubat",
                    "Mart",
                    "Nisan",
                    "Mayıs",
                    "Haziran",
                    "Temmuz",
                    "Ağustos",
                    "Eylül",
                    "Ekim",
                    "Kasım",
                    "Aralık",
                ];
                //var years between 2006 and 1900
                var years = [];
                for (var i = 2006; i > 1900; i--) {
                    years.push(i);
                }
                var day = days[0];
                var month = months[0];
                var year = years[0];
                //if birthday first digit is 0 remove it
                if (detail.birthday.charAt(0) === "0") {
                    day = detail.birthday.charAt(1);
                }
                //if birthday second digit is 0 remove it
                if (detail.birthday.charAt(3) === "0") {
                    month = months[parseInt(detail.birthday.charAt(4)) - 1];
                } else {
                    month = months[parseInt(detail.birthday.slice(3, 5)) - 1];
                }

                year = detail.birthday.slice(6, 10);

                //set selected day month and year
                document.getElementById("day").innerHTML = days.map((day) => {
                    if (day === detail.birthday.slice(0, 2)) {
                        return `<option value="${day}" selected>${day}</option>`;
                    } else {
                        return `<option value="${day}">${day}</option>`;
                    }
                });

                //take birthday month and select months[month-1]
                document.getElementById("month").innerHTML = months.map(
                    (month) => {
                        if (
                            month ===
                            months[parseInt(detail.birthday.slice(3, 5)) - 1]
                        ) {
                            return `<option value="${month}" selected>${month}</option>`;
                        } else {
                            return `<option value="${month}">${month}</option>`;
                        }
                    }
                );

                //if years[i] is equal to year-1 select it
                document.getElementById("year").innerHTML = years.map(
                    (year) => {
                        if (year === parseInt(detail.birthday.slice(6, 10))) {
                            return `<option value="${year}" selected>${year}</option>`;
                        } else {
                            return `<option value="${year}">${year}</option>`;
                        }
                    }
                );

                //if genders[i] is equal to gender select it
                document.getElementById("gender").innerHTML = genders.map(
                    (gender) => {
                        if (gender === gendercan) {
                            return `<option value="${gender}" selected>${gender}</option>`;
                        } else {
                            return `<option value="${gender}">${gender}</option>`;
                        }
                    }
                );

                setUserDetails(detail);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (userDetails.status === false) {
            navigator("/");
        }
    }, [userDetails]);

    //if setShowResponse is changed and true, dissapear after 3 seconds
    useEffect(() => {
        if (showResponse === "flex") {
            setTimeout(() => {
                setShowResponse("none");
            }, 2500);
        }
    }, [showResponse]);
    const saveClicker = () => {
        var bdDay = document.getElementById("day").value;
        var bdMonth = document.getElementById("month").value;
        var bdYear = document.getElementById("year").value;

        //check if bdDay is single digit add 0 to the beginning
        if (bdDay.length === 1) {
            bdDay = "0" + bdDay;
        }
        //check which month is selected than turn it into number
        if (bdMonth === "Ocak") {
            bdMonth = "01";
        } else if (bdMonth === "Şubat") {
            bdMonth = "02";
        } else if (bdMonth === "Mart") {
            bdMonth = "03";
        } else if (bdMonth === "Nisan") {
            bdMonth = "04";
        } else if (bdMonth === "Mayıs") {
            bdMonth = "05";
        } else if (bdMonth === "Haziran") {
            bdMonth = "06";
        } else if (bdMonth === "Temmuz") {
            bdMonth = "07";
        } else if (bdMonth === "Ağustos") {
            bdMonth = "08";
        } else if (bdMonth === "Eylül") {
            bdMonth = "09";
        } else if (bdMonth === "Ekim") {
            bdMonth = "10";
        } else if (bdMonth === "Kasım") {
            bdMonth = "11";
        } else if (bdMonth === "Aralık") {
            bdMonth = "12";
        }
        var bd = bdDay + "." + bdMonth + "." + bdYear;

        var gender = document.getElementById("gender").selectedOptions[0].value;

        var address = document.getElementById("address").value;

        var currentPass = document.getElementById("currentPass").value;
        var newPass = document.getElementById("newPass").value;
        var newPassConfirm = document.getElementById("newPassConfirm").value;

        if (address === "") {
            setResponse("Adres boş bırakılamaz");
            setShowResponse("flex");
            setColorResponse("red");
        } else {
            //check birthday if its over 18
            var today = new Date();
            var birthDate = new Date(bdYear, bdMonth - 1, bdDay);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if (age < 18) {
                setResponse("18 yaşından küçükler üye olamaz");
                setShowResponse("flex");
                setColorResponse("red");
            } else {
                if (
                    currentPass === "" &&
                    newPass === "" &&
                    newPassConfirm === ""
                ) {
                    setShowResponse("none");
                    setResponse("");
                    setColorResponse("red");
                    axios
                        .post(
                            "https://asbet001.com/v1/updateUserDetails.php",
                            {
                                birthday: bd,
                                gender: gender,
                                address: address,
                            },
                            {
                                headers: {
                                    "Cache-Control": "no-cache",
                                    "Content-Type":
                                        "application/x-www-form-urlencoded",
                                },
                            }
                        )
                        .then((response) => {
                            response = response.data;
                            if (response.status === false) {
                                setResponse(response.message);
                                setShowResponse("flex");
                                setColorResponse("red");
                            }
                            if (response.status === true) {
                                setResponse(response.message);
                                setShowResponse("flex");
                                setColorResponse("green");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    if (currentPass.length < 6) {
                        setResponse("Geçersiz şifre");
                        setShowResponse("flex");
                        setColorResponse("red");
                    } else {
                        if (newPass.length < 6) {
                            setResponse(
                                "Yeni şifre en az 6 karakter olmalıdır"
                            );
                            setShowResponse("flex");
                            setColorResponse("red");
                        } else {
                            if (newPass === currentPass) {
                                setResponse(
                                    "Yeni şifre eski şifre ile aynı olamaz"
                                );
                                setShowResponse("flex");
                                setColorResponse("red");
                            } else {
                                if (newPass !== newPassConfirm) {
                                    setResponse("Şifreler uyuşmuyor");
                                    setShowResponse("flex");
                                    setColorResponse("red");
                                } else {
                                    setShowResponse("none");
                                    setResponse("");
                                    setColorResponse("red");

                                    axios
                                        .post(
                                            "https://asbet001.com/v1/updateUserDetails.php",
                                            {
                                                currentPass: currentPass,
                                                newPass: newPass,
                                            },
                                            {
                                                headers: {
                                                    "Cache-Control": "no-cache",
                                                    "Content-Type":
                                                        "application/x-www-form-urlencoded",
                                                },
                                            }
                                        )
                                        .then((response) => {
                                            response = response.data;
                                            if (response.status === false) {
                                                setResponse(response.message);
                                                setShowResponse("flex");
                                                setColorResponse("red");
                                            }
                                            if (response.status === true) {
                                                setResponse(response.message);
                                                setShowResponse("flex");
                                                setColorResponse("green");
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                    axios
                                        .post(
                                            "/v1/updateUserDetails",
                                            {
                                                birthday: bd,
                                                gender: gender,
                                                address: address,
                                            },
                                            {
                                                headers: {
                                                    "Cache-Control": "no-cache",
                                                    "Content-Type":
                                                        "application/x-www-form-urlencoded",
                                                },
                                            }
                                        )
                                        .then((response) => {
                                            response = response.data;
                                            //if showResponse is false, show response.message
                                            if(showResponse === "none"){
                                                if (response.status === false) {
                                                    setResponse(response.message);
                                                    setShowResponse("flex");
                                                    setColorResponse("red");
                                                }
                                                if (response.status === true) {
                                                    setResponse(response.message);
                                                    setShowResponse("flex");
                                                    setColorResponse("green");
                                                }
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    return (
        <div className="profilemain">
            <div className="container">
                <div
                    className="container-error"
                    style={{
                        display: showResponse,
                        backgroundColor: colorResponse,
                    }}
                >
                    <h1 className="container-error-content">{response}</h1>
                </div>
                <h1 className="container-title">Hesabım</h1>
                <div className="profile">
                    <div className="profile-content">
                        <div className="profile-input profile-input-disabled">
                            <label htmlFor="name">Ad</label>
                            <input
                                type="text"
                                id="name"
                                value={userDetails.firstname}
                                disabled
                            />
                        </div>
                        <div className="profile-input profile-input-disabled">
                            <label htmlFor="name">Soyad</label>
                            <input
                                type="text"
                                id="surname"
                                value={userDetails.lastname}
                                disabled
                            />
                        </div>
                        <div className="profile-input profile-input-disabled">
                            <label htmlFor="name">Email</label>
                            <input
                                type="text"
                                id="email"
                                value={userDetails.email}
                                disabled
                            />
                        </div>
                        <div className="profile-input profile-input-disabled">
                            <label htmlFor="name">Üye Numarası</label>
                            <input
                                type="text"
                                id="id"
                                value={userDetails.userid}
                                disabled
                            />
                        </div>
                        <div className="profile-phone">
                            <div className="profile-input profile-input-disabled area-code">
                                <label htmlFor="name">Onay Kodu</label>
                                <input
                                    type="text"
                                    id="countrycode"
                                    value="+90"
                                    disabled
                                />
                            </div>
                            <div className="profile-input profile-input-disabled phone-number">
                                <label htmlFor="name">Cep telefonu</label>
                                <input
                                    type="text"
                                    id="phone"
                                    value={userDetails.phone}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="profile-birthday">
                            <label htmlFor="name">Doğum Tarihi</label>
                            <div className="profile-input birthday-selection">
                                <select name="day" id="day"></select>
                                <select name="month" id="month"></select>
                                <select name="year" id="year"></select>
                            </div>
                        </div>
                        <div className="profile-input">
                            <label htmlFor="name">Cinsiyet</label>
                            <select id="gender" name="gender"></select>
                        </div>
                    </div>
                    <div className="profile-end">
                        <span className="profile-line"></span>
                    </div>
                </div>
                <div className="profile">
                    <div className="profile-title">
                        <h1>Konum</h1>
                    </div>
                    <div className="profile-content">
                        <div className="profile-address">
                            <div className="profile-input profile-input-disabled">
                                <label htmlFor="name">Ülke</label>
                                <input
                                    type="text"
                                    id="country"
                                    value="Türkiye"
                                    disabled
                                />
                            </div>
                            <div className="profile-input">
                                <label htmlFor="name">Adres</label>
                                <input type="text" id="address" />
                            </div>
                        </div>
                    </div>
                    <div className="profile-end">
                        <span className="profile-line"></span>
                    </div>
                </div>
                <div className="profile">
                    <div className="profile-title">
                        <h1>AsBet Şifreniz</h1>
                    </div>
                    <div className="profile-content">
                        <div className="profile-input profile-nowpassword">
                            <label htmlFor="name">Mevcut şifre</label>
                            <input
                                type="text"
                                id="currentPass"
                                placeholder="Mevcut şifre"
                            />
                        </div>
                        <div className="profile-input">
                            <label htmlFor="name">Yeni şifre</label>
                            <input
                                type="text"
                                id="newPass"
                                placeholder="Yeni şifre"
                            />
                        </div>
                        <div className="profile-input">
                            <label htmlFor="name">Şifreyi onayla</label>
                            <input
                                type="text"
                                id="newPassConfirm"
                                placeholder="Şifreyi onayla"
                            />
                        </div>
                    </div>
                    <div className="profile-end">
                        <span className="profile-line"></span>
                    </div>
                    <div className="profile-submit">
                        <button
                            className="profile-submit-button"
                            onClick={saveClicker}
                        >
                            KAYDET
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
