import "./App.css";
import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Casino from "./Pages/Casino";
import NavBar from "./Components/NavBar";
import PreLoader from "./Components/PreLoader";
import PlayGame from "./Pages/PlayGame";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Profile from "./Pages/Profile";
import Deposit from "./Pages/Deposit";
import LogOut from "./Pages/LogOut";
import axios from "axios";
import Withdraw from "./Pages/Withdraw";
import Bonuses from "./Pages/Bonuses";
import Gamblings from "./Pages/Gamblings";
import ForgotPassword from "./Pages/ForgotPassword";
import PaymentProcessor from "./Pages/PaymentProcessor";
import Transactions from "./Pages/Transactions";
import Vip from "./Pages/Vip";

function App() {
    var [details, setDetails] = useState([]);
    var [lastShow, setLastShow] = useState([]);
    var timeSet = 10000;
   
    function getDetails() {
        axios
            .get("https://asbet001.com/v1/getMyDetails")
            .then((response) => {
                var detail = response.data;
                //if balance is format in like 10 or 0 change it to 10.00 or 0.00 if its 10.5 or 0.5 change it to 10.50 or 0.50 if its 10.55 or 0.55 dont change it
                if (detail.logged === true) {
                    if (detail.balance.toString().split(".")[1] === undefined) {
                        detail.balance = detail.balance + ".00";
                    } else if (
                        detail.balance.toString().split(".")[1].length === 1
                    ) {
                        detail.balance = detail.balance + "0";
                    }
                }
                setDetails(detail);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        document.getElementById("preloader").style.display = "none";
        getDetails();
        setLastShow({ lastShows: true });
        setInterval(() => {
            getDetails();
        }, timeSet);
       
    }, []);

    useEffect(() => {
        //change balance onchange of details
        var balance = document.getElementById("balance");
        var balance2 = document.getElementsByClassName("balance-color")[0];
        if (balance !== null) {
            balance.innerHTML = "₺ " + details.balance;
            balance2.innerHTML = "₺ " + details.balance;
        }
    }, [details]);
    //<Route path="/gamblings" element={<Gamblings detaylar={details} />} />

    return (
        <div className="App">
            <PreLoader />
            <NavBar detaylar={details} />
            <Routes>
                <Route
                    path="*"
                    element={
                        <Home detaylar={details} lastShowStatus={lastShow} />
                    }
                />
                <Route
                    path="/"
                    element={
                        <Home detaylar={details} lastShowStatus={lastShow} />
                    }
                />
                <Route path="/casino" element={<Casino detaylar={details} />} />
                <Route path="/login" element={<Login getdata={details} />} />
                <Route path="/register" element={<Register getdata={details} />} />
                <Route path="/profile" element={<Profile getdata={details} />} />
                <Route path="/playGame" element={<PlayGame detaylar={details} />} />
                <Route path="/bonuses" element={<Bonuses detaylar={details} />} />
                <Route path="/playGame/:gameid" element={<PlayGame detaylar={details} />} />
                <Route path="/deposit" element={<Deposit detaylar={details} />} />
                <Route path="/gamblings" element={<Gamblings detaylar={details} />} />
                <Route path="/transactions" element={<Transactions detaylar={details} />} />
                <Route path="/withdraw" element={<Withdraw detaylar={details} />} />
                <Route path="/logout" element={<LogOut />} />
                <Route path="/vip" element={<Vip />} />
                <Route path="/forgotPassword" element={<ForgotPassword getdata={details} />} />
                <Route path="/paymentProcessor" element={<PaymentProcessor getdata={details} />} />
            </Routes>
        </div>
    );
}

export default App;
