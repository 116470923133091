import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Assets/css/RegisterStep2.css";
import Logo from "../Assets/img/mainlogo.png";
import axios from "axios";

const RegisterStep2 = () => {
    const navigate = useNavigate();
    const [response, setResponse] = useState("");
    const [responseShow, setResponseShow] = useState("none");
    useEffect(() => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1; // jan=0; feb=1 .......
        var day = dtToday.getDate();
        var year = dtToday.getFullYear() - 18;
        if (month < 10) month = "0" + month.toString();
        if (day < 10) day = "0" + day.toString();
        var minDate = year + "-" + month + "-" + day;
        //compare the selected date with current date
    }, []);

    const formatPhoneNumber = () => {
        let formattedNumber = document.getElementById("gsmNumber").value;
        const value = formattedNumber;
        // Filter non numbers
        const regex = (input) => {
            // Max length 13
            let cleanedNumber = input.replace(/[^0-9]/g, "").slice(0, 10);
            // 3. haneden sonra boşluk ekle
            if (cleanedNumber.length > 3) {
                cleanedNumber =
                    cleanedNumber.slice(0, 3) + " " + cleanedNumber.slice(3);
            }
            // 6. haneden sonra boşluk ekle
            if (cleanedNumber.length > 7) {
                cleanedNumber =
                    cleanedNumber.slice(0, 7) + " " + cleanedNumber.slice(7);
            }
            if (cleanedNumber.length > 10) {
                cleanedNumber =
                    cleanedNumber.slice(0, 10) + " " + cleanedNumber.slice(10);
            }
            // Başında sıfır varsa sıfırı kaldır
            if (cleanedNumber.startsWith("0")) {
                cleanedNumber = cleanedNumber.slice(1);
            }
            return cleanedNumber;
        };

        // Giriş değeri 1'den büyükse, ilk 4 haneyi silerek regexe soksun
        if (value.length > 1) {
            formattedNumber = value.slice(4);
        }

        document.getElementById("gsmNumber").value =
            "+90 " + regex(formattedNumber);
    };

    const formatBirthday = () => {
        var dob = document.getElementById("birthday").value;
        //put . after 2 digits and 4 digits and max length 10
        dob = dob.replace(/\./g, "");

        //check last character is number
        if (isNaN(dob.slice(-1))) {
            dob = dob.slice(0, -1);
        }

        // filter day 01 - 31
        if (dob.length === 2) {
            if (dob > 31) {
                dob = "0" + dob.slice(0, 1);
            }
            if (dob < 1) {
                dob = dob.slice(0, 1);
            }
        }
        // filter month 01 - 12
        if (dob.length === 4) {
            var month = dob.slice(2, 4);
            if (month > 12) {
                dob = dob.slice(0, 2) + "0" + dob.slice(2, 3);
            }
            if (month < 1) {
                dob = dob.slice(0, -1);
            }
        }
        
        // filter year 1900 - 2021
        if (dob.length === 8) {
            var year = dob.slice(4, 8);
            //should smaller than 2007 and bigger than 1900
            if (year > 2007 || year < 1900) {
                dob = dob.slice(0, 4) + "2006";
            }
        }

        if (dob.length > 2) {
            dob = dob.slice(0, 2) + "." + dob.slice(2);
        }
        if (dob.length > 5) {
            dob = dob.slice(0, 5) + "." + dob.slice(5);
        }
        if (dob.length > 10) {
            dob = dob.slice(0, 10);
        }
        document.getElementById("birthday").value = dob;
    };

    const checkFilled = () => {
        var gender = document.getElementById("gender").value;
        var birthday = document.getElementById("birthday").value;
        var gsmNumber = document.getElementById("gsmNumber").value;
        var password = document.getElementById("password").value;
        var passwordConfirm = document.getElementById("passwordConfirm").value;
        document.getElementById("promoCode").value = document.getElementById("promoCode").value.toUpperCase();
        if (
            gender === "none" ||
            birthday === "" ||
            gsmNumber === "" ||
            password === "" ||
            passwordConfirm === ""
        ) {
            return false;
        } else {
           
            return true;
        }
    };

    const checkBirthday = () => {
        var dob = document.getElementById("birthday").value;
        var year = dob.slice(6, 10);
        var month = dob.slice(3, 5);
        var day = dob.slice(0, 2);
        var age = 18;
        var mydate = new Date();
        mydate.setFullYear(year, month - 1, day);

        var currdate = new Date();
        var setDate = new Date();
        setDate.setFullYear(mydate.getFullYear() + age, month - 1, day);

        if (
            currdate.getFullYear() - mydate.getFullYear() < age ||
            (currdate.getFullYear() - mydate.getFullYear() === age &&
                currdate.getMonth() < mydate.getMonth()) ||
            (currdate.getFullYear() - mydate.getFullYear() === age &&
                currdate.getMonth() === mydate.getMonth() &&
                currdate.getDate() < mydate.getDate())
        ) {
            return false;
        } else {
            return true;
        }
    };

    //make lowercase as supporting turkish characters incoming string
    const lowerCase = (string) => {
        return string.toLowerCase();
    };

    const sendRegister = () => {
        axios
            .post(
                "https://asbet001.com/v1/Register.php",
                {
                    firstName: document.getElementById("firstName").value,
                    middleName: document.getElementById("secondName").value,
                    lastName: document.getElementById("lastName").value,
                    userName: document.getElementById("userName").value,
                    tcNo: document.getElementById("tcNo").value,
                    email: document.getElementById("email").value,
                    gender: document.getElementById("gender").value,
                    birthday: document.getElementById("birthday").value,
                    gsmNumber: document.getElementById("gsmNumber").value,
                    password: document.getElementById("password").value,
                    promoCode: document.getElementById("promoCode").value,
                },
                {
                    headers: {
                        "Cache-Control": "no-cache",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((res) => {
                var response = res.data;
                if (response.status === true) {
                    window.location.href = "/";
                } else {
                    setResponse(response.message);
                    setResponseShow("flex");
                }
            })
            .catch((err) => {
                setResponse(
                    "Kayıt Esnasında Bir Hata Oluştu. Lütfen Canlı Desteğe Bağlanın."
                );
                setResponseShow("flex");
            });
    };

    const submitRegister = () => {
        var gsmNumber = document.getElementById("gsmNumber").value;
        var password = document.getElementById("password").value;
        var passwordConfirm = document.getElementById("passwordConfirm").value;
        if (checkFilled() === false) {
            setResponse("Lütfen tüm alanları doldurunuz.");
            setResponseShow("flex");
        } else {
            if (!checkBirthday()) {
                setResponse("18 yaşından küçükler kayıt olamaz.");
                setResponseShow("flex");
            } else {
                if (document.getElementById("gsmNumber").value.length < 17) {
                    setResponse("Lütfen geçerli bir telefon numarası giriniz.");
                    setResponseShow("flex");
                } else {
                    var gsmNumber = document.getElementById("gsmNumber").value;
                    axios
                        .get("https://asbet001.com/v1/getPhoneCheck.php?phone=" + gsmNumber)
                        .then((response) => {
                            response = response.data;
                            if (response.exist === true) {
                                setResponse(
                                    "Bu telefon numarası zaten kayıtlı."
                                );
                                setResponseShow("flex");
                            } else {
                                if (password !== passwordConfirm) {
                                    setResponse("Şifreler uyuşmuyor.");
                                    setResponseShow("flex");
                                } else {
                                    if (password.length < 6) {
                                        setResponse(
                                            "Şifre en az 6 karakter olmalıdır."
                                        );
                                        setResponseShow("flex");
                                    } else {
                                        setResponseShow("none");
                                        setResponse("");
                                        sendRegister();
                                    }
                                }
                            }
                        });
                }
            }
        }
    };
    return (
        <div id="RegisterStep2" className="RegisterMain2">
            <div className="RegisterHeader">
                <img src={Logo} alt="Logo" className="RegisterHeaderLogo" />
                <div className="RegisterHeaderRight">
                    <Link to="/login" className="RegisterHeaderRegister">
                        GİRİŞ YAP
                    </Link>
                    <Link to="/" className="RegisterHeaderClose">
                        <span className="RegisterHeaderLine line1"></span>
                        <span className="RegisterHeaderLine line2"></span>
                    </Link>
                </div>
            </div>
            <div className="RegisterContent">
                <h1 className="RegisterHeaderTitle">
                    HEMEN KAYIT OLUN - ADIM 2
                </h1>
                <p
                    className="RegisterHeaderResponse"
                    style={{ display: responseShow }}
                >
                    {response}
                </p>
                <div className="RegisterForm">
                    <input
                        type="text"
                        placeholder="TRY"
                        id="currency"
                        className="RegisterFormInput disabled"
                        disabled
                    />
                    <input
                        type="text"
                        placeholder="Türkiye"
                        id="country"
                        className="RegisterFormInput disabled"
                        disabled
                    />
                    <select
                        type="select"
                        id="gender"
                        className="RegisterFormInput selection"
                    >
                        <option value="none" disabled selected>
                            Cinsiyet Seçiniz
                        </option>
                        <option value="ERKEK">Erkek</option>
                        <option value="KADIN">Kadın</option>
                    </select>

                    <input
                        type="text"
                        placeholder="Doğum Tarihi"
                        id="birthday"
                        className="RegisterFormInput dateselector"
                        onChange={formatBirthday}
                    />
                    <input
                        type="text"
                        placeholder="+90 (5**) *** ****"
                        id="gsmNumber"
                        className="RegisterFormInput"
                        onChange={formatPhoneNumber}
                    />
                    <input
                        type="password"
                        placeholder="Şifre"
                        id="password"
                        className="RegisterFormInput"
                    />
                    <input
                        type="password"
                        placeholder="Şifreyi Onayla"
                        id="passwordConfirm"
                        className="RegisterFormInput"
                    />
                    <input
                        type="tex"
                        placeholder="Promosyon Kodu"
                        id="promoCode"
                        className="RegisterFormInput"
                    />
                    <button
                        id="RegisterStep2Submit"
                        className="RegisterFormButton"
                        onClick={submitRegister}
                    >
                        KAYIT OL
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegisterStep2;
