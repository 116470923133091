import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Assets/css/Vip.css";

const Vip = (details) => {
    var details = details.detaylar;
    var navigate = useNavigate();

    var [transactions, setTransactions] = useState([]);
    var [title, setTitle] = useState("VİP");
    useEffect(() => {
        document.title = "Vip | AsBet";
      }, []);
    return (
        <div className="vip-card">
        <div className="vip-header">
          <div className="vip-logo">
            <a href=".">ASBET</a>
          </div>
        </div>
        <div className="vip-content">
          <div className="vip-title-holder">
            <h1>VİP Ekranları Yakında Sizlerle</h1>
            <p>VİP imkanlarıyla ilerleyen zamanlarda sizin kullanımınıza sunuyoruz!</p>
          </div>
        </div>
        <div className="vip-footer">
          <span>made by <a className="vip-underlined" href="https://asbet001.com/" target="_blank" rel="noopener noreferrer">asbet</a> using <a className="vip-underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">React</a></span>
        </div>
      </div>
    );
};

export default Vip;
