import React, { useEffect } from 'react'
import '../Assets/css/Home.css'
import HomeSlider from '../Components/HomeSlider'
import Advertisement from '../Components/Advertisement'
import LastWinners from '../Components/LastWinners'
import PopularGames from '../Components/PopularGames'

const Home = (lastShowStatus) => {
  var lastShowTrue = lastShowStatus.lastShowStatus;
  //change title
  useEffect(() => {
    document.title = "Anasayfa | AsBet";
  }, []);
  return (
    <div className='mainhome'>
      <div className='maincontainer'>
        <HomeSlider />
        {lastShowTrue ? <LastWinners detaylar={lastShowStatus.detaylar} /> : null}
        {lastShowTrue ? <PopularGames detaylar={lastShowStatus.detaylar} /> : null}
      </div>
    </div>
  )
}

export default Home