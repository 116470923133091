import React from "react";
import "../Assets/css/HomeSlider.css";
import axios from "axios";



const colors = [];
const delay = 3500;

const HomeSlider = () => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    //send request to /api/pages/home/slider.php
    axios.get("https://asbet001.com/v1/slider.php").then((response) => {
      console.log("sliders api ",response.data);
      var data = response.data;
      data && data.forEach((element) => {
        //push index and imgurl
        colors.push(element.imgurl);
      });
    });
  }
  , []);

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((imgurl, index) => (
          <img className="slide" key={index} src={imgurl} alt={index}></img>
        ))}
      </div>

     
    </div>
  );
};

export default HomeSlider;
