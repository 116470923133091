import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const LogOut = () => {
  const navigate = useNavigate();
  document.getElementById("preloader").style.display = "flex";
  useEffect(() => {
    axios
      .get("https://asbet001.com/v1/LogOut.php")
      .then((response) => {
        var detail = response.data;
        if (detail.success === true) {
          document.getElementById("preloader").style.display = "none";
          window.location.href = "/";
        }else{
          document.getElementById("preloader").style.display = "none";
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return <div> </div>;
};

export default LogOut;
