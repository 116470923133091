import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Assets/css/Gamblings.css";

const Gamblings = (details) => {
    var details = details.detaylar;
    var navigate = useNavigate();

    /*if (details.logged === false) {
        navigate("/login");
    }*/

    var [transactions, setTransactions] = useState([]);
    var [title, setTitle] = useState("BAHİS GEÇMİŞİ");
    var [titleColor, setTitleColor] = useState("#460073");
    var [titleSize, setTitleSize] = useState("1.1em");
    const getGames = (from, to) => {
        axios
            .post("https://asbet001.com/v1/getPlayingHistory", {
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((response) => {
                if (
                    response.data.length === 1 &&
                    response.data[0].status === false
                ) {
                    console.log(response.data[0].message);
                } else {
                    console.log(response.data);
                    response.data.forEach((item) => {
                        var gameid = item.gameid;
                        axios
                            .get(
                                "https://asbet001.com/v1/getGameList?gameid=" +
                                    gameid
                            )
                            .then((responses) => {
                                if (responses.data[0].status !== 0) {
                                    var gameName = responses.data[0].name;
                                    item.gameName = gameName;
                                    console.log(item);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
                }
            });
    };

    useEffect(() => {
        /*if(details.logged === false){
            navigate("/login");
        }*/

        //send /v1/getPlayingHistory url a post request with username="keygen"
        axios
            .post("https://asbet001.com/v1/getPlayingHistory", {
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((response) => {
                if (response.data.status === false) {
                    console.log(response.data.message);
                } else {
                    console.log(response.data);
                    setTransactions(response.data);
                }
            });
    }, []);
    return (
        <div className="gambling-container-fluid mt-5">
            <div
                className="gambling-container-title"
                style={{ backgroundColor: titleColor }}
            >
                <h1 style={{ fontSize: titleSize }}>{title}</h1>
                <div className="close-btn">
                    <span className="close-line"></span>
                    <span className="close-line"></span>
                </div>
            </div>
            <div className="gambling-container">
                <div className="gambling-transaction-history-container">
                    <table className="gambling-transaction-table">
                            <tr style={{backgroundColor: '#ffd91d'}}>
                                <th>Oyun ID</th>
                                <th>İşlem Tarihi</th>
                                <th>Tutar</th>
                                <th>Kazanılan</th>
                                <th>Bakiye</th>
                            </tr>
                        <tbody>
                        {transactions.map(transaction => (
                            <tr key={transaction.id}>
                                <td style={{fontWeight: '600'}}>{transaction.gameid}</td>
                                <td style={{fontWeight: '600'}}>{transaction.date}</td>
                                <td style={{color: 'red', fontWeight: '600'}}>{'-' + transaction.playedAmount} ₺</td>
                                <td style={{color: 'green', fontWeight: '600'}}>{transaction.winAmount > 0 ? '+' + transaction.winAmount : transaction.winAmount} ₺</td>
                                <td style={{color: 'green', fontWeight: '600'}}>{transaction.balance} ₺</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Gamblings;
