import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Assets/css/Deposit.css";

const Deposit = (details) => {
    details = details.detaylar;
    var navigate = useNavigate();

    if (details.logged === false) {
        navigate("/login");
    }


    var [methods, setMethods] = useState([]);
    var [title, setTitle] = useState("PARA YATIR");
    var [titleColor, setTitleColor] = useState("#ad1ddb");
    var [titleSize, setTitleSize] = useState("1.1em");

    const getDepositUrl = (id) => {
        axios
            .post(
                "https://asbet001.com/v1/Payments/GetDepositUrl.php",
                {
                    methodID: id,
                },
                {
                    headers: {
                        "Cache-Control": "no-cache",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((response) => {
                if (response.data.status === false) {
                    setTitle(response.data.message);
                    setTitleColor("red");
                    //if on pc make title bigger
                    if (window.innerWidth > 768) {
                        setTitleSize("1.1em");
                    } else {
                        setTitleSize("0.8em");
                    }
                    setTimeout(() => {
                        setTitle("PARA YATIR");
                        setTitleColor("#0078ff");
                        setTitleSize("1.1em");
                    }, 3000);
                } else {
                    console.log("response data",response.data);
                    if(response.data.path !== undefined || response.data.path !== null || response.data.path !== ""){
                        setTitle("PARA YATIR");
                        setTitleColor("#0078ff");
                        setTitleSize("1.1em");
                        var url = response.data.path + "?methodId=" + id + "&token=" + response.data.token;
                        console.log(url);
                        window.location.href = url;
                    }else{
                        setTitle("Bir hata oluştu, lütfen canlı desteğe bağlanın!");
                        setTitleColor("red");
                        //if on pc make title bigger
                        if (window.innerWidth > 768) {
                            setTitleSize("1.1em");
                        } else {
                            setTitleSize("0.8em");
                        }
                        setTimeout(() => {
                            setTitle("PARA YATIR");
                            setTitleColor("#0078ff");
                            setTitleSize("1.1em");
                        }, 3000);
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        //if user is not logged in, redirect to login page
        /*if (details.logged === false) {
            navigate('/login')
        }*/
        //send request to /v1/Payments/GetMethods.php
        axios
            .post("https://asbet001.com/v1/Payments/GetMethods.php", {
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((response) => {
                if (
                    response.data.length === 1 &&
                    response.data[0].status === false
                ) {
                    console.log(response.data[0].message);
                } else {
                    setMethods(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            document.title = "Para Yatır | AsBet";
    }, []);

    return (
        <div className="container-fluid mt-5">
            <div
                className="container-title"
                style={{ backgroundColor: titleColor }}
            >
                <h1 style={{ fontSize: titleSize }}>{title}</h1>
                <div className="close-btn">
                    <span className="close-line"></span>
                    <span className="close-line"></span>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {methods.map((method) => {
                                        return (
                                            <div
                                                className="row tablocan"
                                                key={method.id}
                                                onClick={() =>
                                                    getDepositUrl(method.id)
                                                }
                                            >
                                                <div className="col-lg-4 card-img">
                                                    <img
                                                        src={method.paymentImg}
                                                        alt="logo"
                                                    />
                                                </div>
                                                <div className="col-lg-8 card-info">
                                                    <h4>Limitler</h4>
                                                    <p>
                                                        ₺{method.minLimit} - ₺
                                                        {method.maxLimit}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deposit;
