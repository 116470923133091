import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from "axios";
import { useState, useEffect } from "react";
import "../Assets/css/Withdraw.css";

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider  from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

var getPaymentDetails = (paymentId, token, updateIban, updateDetails, updateType) => {
    //check if username and password is empty
    if (
        paymentId === "" || !token
    ) {
        /*setResponse("Kullanıcı Adı veya Şifre Boş Olamaz.");
        setResponseShow("flex");*/
        return;
    } else {
        //login request with no cors
        axios
        .post(
            "https://asbet001.com/v1/GetPaymentDetails",
            {
            methodId: paymentId,
            token: token
            },
            {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            }
        )
        .then((res) => {
            var response = res.data;
            console.log('login response ', response);
            if (response.status === true) {
            console.log(response);
            updateIban(response.methodPayment);
            updateDetails(response.methodDetail);
            updateType(response.type);
            } else {
            console.log('error');
            /*setResponse(response.message);
            setResponseShow("flex");*/
            }
        })
        .catch((err) => {
            /*setResponse(
            "Giriş Esnasında Bir Hata Oluştu. Lütfen Canlı Desteğe Bağlanın."
            );
            setResponseShow("flex");*/
        });
    }
};

var withdrawRequest = (updateSection, amount, type, iban, details) => {
    //login request with no cors
    axios
    .post(
        "https://asbet001.com/v1/WithdrawRequest",
        {
            amount: amount,
            type: type,
            iban: iban,
            details: details
        },
        {
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        }
    )
    .then((res) => {
        var response = res.data;
        console.log('login response ', response);
        if (response.status === true) {
            console.log("withdrawRequest response", response);
            updateSection();
        } else {
        console.log('error');
        /*setResponse(response.message);
        setResponseShow("flex");*/
        }
    })
    .catch((err) => {
        /*setResponse(
        "Giriş Esnasında Bir Hata Oluştu. Lütfen Canlı Desteğe Bağlanın."
        );
        setResponseShow("flex");*/
    });
};

const step1 = (updateSection, amount, updateAmount) => {
    const handleChange = (e) => {
        const { value } = e.target;
        console.log("value",value);
        // Sadece sayıları kabul eden bir regex kullanarak kontrol edelim
        if (/^\d+$/.test(value) || value === '') {
          updateAmount(value);
        }
    };
    return (
        <Container fixed>
            <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography color={'#000000'} component="h1" variant="h5">
                    Transfer Yapacağınız Tutar 
                </Typography>
                <Box noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="amount"
                    label="Tutar ₺"
                    name="amount"
                    autoComplete="amount"
                    autoFocus
                    value={amount}
                    InputProps={{ inputProps: { inputMode: 'numeric', pattern: '[0-9]*' } }}
                    onChange={handleChange}
                    />
                    <Button
                    color='secondary'
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => updateSection()}
                    >
                    ÇEKİM YAP
                    </Button>
                </Box>
            </Box>
            <Box style={{backgroundColor: '#89CFF0'}} color={'#000000'} sx={{p: 2, m: 1, mb: 1}}>
                <Typography color={'#000000'} component="p" variant="p">
                    Para çekimi için sadece kendi adınıza kayıtlı iban bilgileri ile çekim yapabilirsiniz. 
                    Eğer başka bir isim soy isim ile çekim yapmak isterseniz reddedilecektir.
                </Typography>
            </Box>
            <Box sx={{p: 1}}/>
        </Container>
    );
}

const step2 = (updateSection, amount, iban, details, updateIban, updateDetails) => {
    const handleIbanChange = (e) => {
        const { value } = e.target;
        console.log("value",value);
        // Sadece sayıları kabul eden bir regex kullanarak kontrol edelim
        updateIban(value);
    };
    const handleDetailsChange = (e) => {
        const { value } = e.target;
        console.log("value",value);
        // Sadece sayıları kabul eden bir regex kullanarak kontrol edelim
        updateDetails(value);
    };
    return (
        <Container fixed>
            <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography color={'#000000'} component="h1" variant="h5">
                    Hesap Bilgileri
                </Typography>
                <Box noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="amount"
                    label="Tutar ₺"
                    name="amount"
                    value={amount}
                    autoComplete="amount"
                    disabled
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="iban"
                    label="IBAN"
                    name="iban"
                    value={iban}
                    autoComplete="iban"
                    onChange={handleIbanChange}
                    inputProps={{ maxLength: 32 }}
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="ibanisim"
                    label="İsim Soyisim"
                    name="ibanisim"
                    value={details}
                    autoComplete="ibanisim"
                    onChange={handleDetailsChange}
                    />
                    <Button
                    color='secondary'
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => withdrawRequest(updateSection, amount, "havale", iban, details)}
                    >
                    ÇEKİM TALEBİ
                    </Button>
                </Box>
            </Box>
            <Box style={{backgroundColor: '#89CFF0'}} color={'#000000'} sx={{p: 2, m: 1, mb: 1}}>
                <Typography color={'#000000'} component="p" variant="p">
                    Hesap bilgilerinizi eksiksiz olarak girdikten sonra "PARA ÇEK" butonuna basarak çekim talebi oluşturun. 
                    15 dakika içerisinde paranız hesabınıza geçecektir.
                </Typography>
            </Box>
            <Box sx={{p: 1}}/>
        </Container>
    );
}

const step3 = (navigate) => {
    return (
        <Container fixed>
            <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                <Typography color={'#000000'} component="h1" variant="h5">
                    ÇEKİM TALEBİ ALINDI
                </Typography>
                <Box component="form" onSubmit={()=>{}} noValidate sx={{ mt: 1 }}>
                    <img style={{width: 250}} src="https://asbet001.com/static/media/tick.png" alt="Ödeme Onayı"/>
                </Box>
                <Button
                    color='secondary'
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => navigate("/")}
                    >
                    ANASAYFAYA DÖN
                    </Button>
            </Box>
            <Box style={{backgroundColor: '#89CFF0'}} color={'#000000'} sx={{p: 2, m: 1, mb: 1}}>
                <Typography color={'#000000'} component="p" variant="p">
                    Çekim talebiniz başarıyla alındı. Bilgilerinizi doğru girdiyseniz 15 dakika içerisinde paranız hesabınıza geçecektir.
                </Typography>
            </Box>
            <Box sx={{p: 1}}/>
        </Container>
    );
}

const Withdraw = (getdata) => {
    const navigate = useNavigate();
    if (getdata.getdata.logged === false) {
        //router push to home
        navigate("/");
    }
    const [searchParams] = useSearchParams();
    var [amount, setAmount] = useState(amount || '');
    var [iban, setIban] = useState(iban || 'TR');
    var [detail, setDetail] = useState(detail || '');
    var [type, setType] = useState(type || 'havale');
    var [section, setSection] = useState(section || 0);
    var [token, setToken] = useState(token || searchParams.get('token') || '');
    var [paymentId, setPaymentId] = useState(paymentId || searchParams.get('methodId') || 0);

    const sectionUpdate = () => {
        setSection(section + 1); // Correct, update state in an event handler
    };
    const amountUpdate = (newAmount) => {

        // Sadece sayıları kabul eden bir regex kullanarak kontrol edelim
        if (/^\d+$/.test(newAmount) || newAmount === '') {
            setAmount(newAmount);
        }
    };
    const ibanUpdate = (newIban) => {
        if (newIban.length > 1 && newIban.length < 27) {
            setIban(newIban); // Correct, update state in an event handler
        }
    };
    const detailUpdate = (newDetail) => {
        setDetail(newDetail); // Correct, update state in an event handler
    };
    const typeUpdate = (newType) => {
        setType(newType); // Correct, update state in an event handler
    };

    useEffect(() => {
        /*if(section == 0 && paymentId && token) {
            getPaymentDetails(paymentId, token, ibanUpdate, detailUpdate, typeUpdate);
        } else {
            console.log("section" , section)
            section = 0;
        }*/
    }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container fixed component="main" style={{backgroundColor: '#f5f5f5'}}>
        <CssBaseline />
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                    <img
                        style={{maxWidth: '300px', maxHeight: '40px'}}
                        srcSet={`http://asbet001.com/static/media/mainlogo.79eda52b6550529fdc99.png?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`http://asbet001.com/static/media/mainlogo.79eda52b6550529fdc99.png?w=164&h=164&fit=crop&auto=format`}
                        alt={'Asbet Logo'}
                        loading="lazy"
                    />
                </Grid>
            </Grid>
            <Divider style={{py: 0, width: '100%', borderRadius: 2, border: '1px solid', borderColor: '#f5f5f5', backgroundColor: 'background.paper'}} orientation="vertical" flexItem />
        </Box>
        {section == 0 ? step1(() => sectionUpdate(), amount, amountUpdate) : section == 1 ? step2(() => sectionUpdate(), amount, iban, detail, ibanUpdate, detailUpdate) : step3(navigate)}
      </Container>
    </ThemeProvider>
  );
};

export default Withdraw;
