import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/css/RegisterStep1.css";
import Logo from "../Assets/img/mainlogo.png";
import axios from "axios";

const RegisterStep1 = () => {
    const [response, setResponse] = useState("");
    const [responseShow, setResponseShow] = useState("none");
    //tc validator
    function validTCKN() {
        var value = document.getElementById("tcNo").value;
        value = String(value);

        // T.C. identity number should have 11 digits and first should be non-zero.
        if (!/^[1-9]\d{10}$/.test(value)) return false;

        const digits = value.split("");
        // store last 2 digits (10th and 11th) which are actually used for validation
        const d10 = Number(digits[9]);
        const d11 = Number(digits[10]);
        // we'll also need the sum of first 10 digits for validation
        let sumOf10 = 0;
        let evens = 0;
        let odds = 0;

        digits.forEach((d, index) => {
            d = Number(d);
            if (index < 10) sumOf10 += d;
            if (index < 9) {
                if ((index + 1) % 2 === 0) {
                    evens += d;
                } else {
                    odds += d;
                }
            }
        });

        // check if the unit-digit of the sum of first 10 digits equals to the 11th digit.
        if (sumOf10 % 10 !== d11) return false;

        // check if unit-digit of the sum of odds * 7 and evens * 9 is equal to 10th digit.
        if ((odds * 7 + evens * 9) % 10 !== d10) return false;

        // check if unit-digit of the sum of odds * 8 is equal to 11th digit.
        if ((odds * 8) % 10 !== d11) return false;

        return true;
    }
    // Check email
    const checkEmail = async () => {
        const email = document.getElementById("email").value;
        email.toLowerCase();
        try {
            const response = await axios.get(
                `https://asbet001.com/v1/getEmailCheck.php?email=${email}`
            );
            const status = response.data.exist;
            if (status) {
                return "Hata";
            } else {
                return "Başarı";
            }
        } catch (error) {
            console.error("Error checking email:", error);
        }
    };

    const emailValidate = async () => {
        const email = document.getElementById("email").value;
        if (email.includes("@") && email.includes(".")) {
            return true;
        } else {
            return false;
        }
    };

    // Check username with axios
    const checkUserName = async () => {
        const userName = document.getElementById("userName").value;
        try {
            const response = await axios.get(
                `https://asbet001.com/v1/getUsernameCheck.php?username=${userName}`
            );
            const status = response.data.exist;
            if (status) {
                return "Hata";
            } else {
                return "Başarı";
            }
        } catch (error) {
            console.error("Error checking username:", error);
        }
    };

    // Check TC number
    const checkTcNo = async () => {
        const tcNo = document.getElementById("tcNo").value;
        try {
            const response = await axios.get(
                `https://asbet001.com/v1/getTcknCheck.php?tckn=${tcNo}`
            );
            const status = response.data.exist;
            if (status) {
                return "Hata";
            } else {
                if (!validTCKN(tcNo)) {
                    return "Hata";
                } else {
                    return "Başarı";
                }
            }
        } catch (error) {
            console.error("Error checking TC number:", error);
        }
    };

    const checkInputs = () => {
        const firstName = document.getElementById("firstName").value;
        const lastName = document.getElementById("lastName").value;
        const userName = document.getElementById("userName").value;
        const tcNo = document.getElementById("tcNo").value;
        const email = document.getElementById("email").value;

        if (
            firstName === "" ||
            lastName === "" ||
            userName === "" ||
            tcNo === "" ||
            email === ""
        ) {
            return false;
        } else {
            //if there is i, replace with İ
            var firstNames = document.getElementById("firstName").value;
            var middleNames = document.getElementById("secondName").value;
            var lastNames = document.getElementById("lastName").value;
            var userNames = document.getElementById("userName").value;
            var emails = document.getElementById("email").value;
            firstNames = firstNames.replace("i", "İ");
            middleNames = middleNames.replace("i", "İ");
            lastNames = lastNames.replace("i", "İ");
            userNames = userNames.replace("i", "İ");
            emails = emails.replace("i", "İ");

            //uppercase 
            document.getElementById("firstName").value = firstNames.toUpperCase();
            document.getElementById("secondName").value = middleNames.toUpperCase();
            document.getElementById("lastName").value = lastNames.toUpperCase();
            document.getElementById("userName").value = userNames.toLowerCase();
            document.getElementById("email").value = emails.toLowerCase();
            return true;
        }
    };
    const RegisterStart = async () => {
        // Register Start Logic
        var filled = await checkInputs();
        if (filled) {
            if (response === "Lütfen tüm alanları doldurun.") {
                setResponseShow("none");
                setResponse("");
            }
            var username = document.getElementById("userName").value;
            var usernameValid = await checkUserName();

            if (usernameValid === "Hata") {
                setResponse("Kullanıcı adı kullanılıyor.");
                setResponseShow("flex");
                return;
            } else if (username.length < 3) {
                setResponse("Kullanıcı adı 3 karakterden az olamaz.");
                setResponseShow("flex");
                return;
            } else {
                var tcValidate = await validTCKN();
                if (!tcValidate) {
                    setResponse("TC Kimlik Numarası hatalı.");
                    setResponseShow("flex");
                    return;
                }else{
                    var tcChecker = await checkTcNo();
                    if (tcChecker === "Hata") {
                        setResponse("TC Kimlik Numarası hatalı.");
                        setResponseShow("flex");
                        return;
                    }else{
                        var emailValidated = await emailValidate();
                        if (!emailValidated) {
                            setResponse("E-Posta hatalı.");
                            setResponseShow("flex");
                            return;
                        }
                        else{
                            var emailChecker = await checkEmail();
                            if (emailChecker === "Hata") {
                                setResponse("E-Posta kullanılıyor.");
                                setResponseShow("flex");
                                return;
                            }else{
                                setResponseShow("none");
                                setResponse("");
                                document.getElementById("RegisterStep1").style.display = "none";
                                document.getElementById("RegisterStep2").style.display = "block";
                                document.body.id = "RegisterPage2";

                            }
                        }
                    }
                }
            }
        } else {
            setResponse("Lütfen tüm alanları doldurun.");
            setResponseShow("flex");
        }
    };
    //onclick enter key
    useEffect(() => {
        //registerform on keydown
        document.getElementById("RegisterStep1").onkeydown = function (e) {
            if (e.key === "Enter") {
                RegisterStart();
            }
        };
    }, []);

    return (
        <div id="RegisterStep1" className="RegisterMain1">
            <div className="RegisterHeader">
                <img src={Logo} alt="Logo" className="RegisterHeaderLogo" />
                <div className="RegisterHeaderRight">
                    <Link to="/login" className="RegisterHeaderRegister">
                        GİRİŞ YAP
                    </Link>
                    <Link to="/" className="RegisterHeaderClose">
                        <span className="RegisterHeaderLine line1"></span>
                        <span className="RegisterHeaderLine line2"></span>
                    </Link>
                </div>
            </div>
            <div className="RegisterContent">
                <h1 className="RegisterHeaderTitle">HEMEN KAYIT OLUN</h1>
                <p
                    className="RegisterHeaderResponse"
                    style={{ display: responseShow }}
                >
                    {response}
                </p>
                <div className="RegisterForm">
                    <input
                        type="text"
                        placeholder="Adı"
                        id="firstName"
                        className="RegisterFormInput"
                    />
                    <input
                        type="text"
                        placeholder="İkinci Adı (Opsiyonel)"
                        id="secondName"
                        className="RegisterFormInput"
                    />
                    <input
                        type="text"
                        placeholder="Soyadı"
                        id="lastName"
                        className="RegisterFormInput"
                    />
                    <input
                        type="text"
                        placeholder="Kullanıcı Adı"
                        id="userName"
                        className="RegisterFormInput"
                    />
                    <input
                        type="text"
                        placeholder="TC Kimlik Numarası"
                        id="tcNo"
                        className="RegisterFormInput"
                    />
                    <input
                        type="email"
                        placeholder="E-Posta"
                        id="email"
                        className="RegisterFormInput"
                    />
                    <button
                        id="RegisterStep1Submit"
                        className="RegisterFormButton"
                        onClick={RegisterStart}
                    >
                        SIRADAKİ ADIM
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegisterStep1;
