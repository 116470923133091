import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "../Assets/css/PlayGame.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const PlayGame = (detaylar, props) => {
  var navigate = useNavigate();

  detaylar = detaylar.detaylar;
  var loginStatus = detaylar.logged;
  const { gameid } = useParams();
  var [gameImg, setGameImg] = useState("");
  var [gameName, setGameName] = useState("");
  var [gameFound, setGameFound] = useState();

    useEffect(() => {
        document.getElementById("preloader").style.display = "flex";
    }
    , []);

  useEffect(() => {
    axios
      .get("https://asbet001.com/v1/getGameList?gameid=" + gameid)
      .then((response) => {
        if (response.data[0].status === 0) {
          setGameFound(false);
        } else {
          setGameFound(true);
          setGameImg(response.data[0].iconurl2);
          setGameName(response.data[0].name);
          document.title = response.data[0].name + " | AsBet";
          document.getElementById("preloader").style.display = "none";

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [gameid]);

  //after page load, if game is not found, redirect to casino page
  useEffect(() => {
    if (gameFound === false) {
      navigate("/casino");
    }
  }, [gameFound]);

  var getGameUrl = (gameid) => {
    axios
      .get("https://asbet001.com/v1/getGameUrl?gameid=" + gameid)
      .then((response) => {
        var gameLink = response.data.url;
        document.getElementById("gameMain").innerHTML =
          "<iframe src='" +
          gameLink +
          "' width='100%' height='100%' frameborder='0' allowfullscreen></iframe>";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="gameMain" className="PlayGame">
      <div className="PlayGameBlurBackground">
        <img src={gameImg} alt={gameName} />
      </div>
      <div className="GameContainer">
        <div className="GameName">{gameName}</div>
        <div className="GameImage">
          <img src={gameImg} alt={gameName} />
        </div>
        <div className="GamePlayButton">
          {loginStatus ? (
            <button onClick={() => getGameUrl(gameid)}>OYNA</button>
          ) : (
            <button>
              <Link to="/login">GİRİŞ YAP</Link>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayGame;
